import { Box, Sx, useMantineTheme } from '@mantine/core'
import { FC } from 'react'

export const SmallButton: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const theme = useMantineTheme()
  const AddButtonSx: Sx = {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    textAlign: 'center',
    paddingRight: theme.spacing.sm,
    paddingLeft: theme.spacing.sm,
    borderRadius: theme.radius.md,
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.sm,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    },
  }

  return <Box sx={AddButtonSx}>{children}</Box>
}

export default SmallButton
