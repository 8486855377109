/* eslint-disable jsx-a11y/label-has-associated-control */
import SmallButton from '@/components/dashboard/form/smallButton'
import useContact from '@/hooks/client/Contact/useContact'
import handleImage from '@/util/imageUpload'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import {
  ActionIcon,
  Button,
  Loader,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { AiFillInstagram } from 'react-icons/ai'
import { BsLinkedin, BsTelegram, BsYoutube } from 'react-icons/bs'

const ContactUsForm = () => {
  const { t } = useTranslation('client')

  const {
    handleSubmit,
    form,
    loading,
    uploadImageLoading,
    setUploadImageLoading,
  } = useContact()
  return (
    <section className="relative">
      <div
        className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block"
        aria-hidden="true"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
        }}
      />
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative bg-white shadow-xl rounded-3xl overflow-hidden">
          <h2 className="sr-only">{t('contact')}</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative overflow-hidden py-10 px-6 bg-secondary sm:px-10 xl:p-12">
              <div
                className="absolute inset-0 pointer-events-none sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3CE7FE" />
                      <stop offset={1} stopColor="#3CE7FE" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3CE7FE" />
                      <stop offset={1} stopColor="#3CE7FE" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full "
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".2"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3CE7FE" />
                      <stop offset={1} stopColor="#3CE7FE" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">
                {t('contactInformation')}
              </h3>
              <p className="mt-6 text-base text-indigo-50 max-w-3xl">
                Jeddah, Kingdom of Saudi Arabia, Al-Baghdadiyah district,
                intersection of Hail Road and Crown Prince Road.
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">{t('phoneNumber')}</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <PhoneIcon
                    className="flex-shrink-0 w-6 h-6 text-cyan-200"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+966570322051</span>
                </dd>
                <dt>
                  <span className="sr-only">{t('phoneNumber')}</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <PhoneIcon
                    className="flex-shrink-0 w-6 h-6 text-cyan-200"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+966564572240 </span>
                </dd>
                <dt>
                  <span className="sr-only">Email</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <MailIcon
                    className="flex-shrink-0 w-6 h-6 text-cyan-200"
                    aria-hidden="true"
                  />
                  <span className="ml-3">info@infinity.edu.sa</span>
                </dd>
              </dl>
              <ul className="mt-8 flex gap-6">
                <li>
                  <a
                    className="text-cyan-200 hover:text-cyan-100"
                    href="https://www.facebook.com/infinity.edu.sa/"
                  >
                    <span className="sr-only">Facebook</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      aria-hidden="true"
                    >
                      <path
                        d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    className="text-cyan-200 hover:text-cyan-100"
                    href="https://twitter.com/infinity_edu_sa"
                  >
                    <span className="sr-only">Twitter</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      aria-hidden="true"
                    >
                      <path
                        d="M7.548 22.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 5.305a9.828 9.828 0 01-2.828.776 4.94 4.94 0 002.165-2.724 9.867 9.867 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 3.9a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 9.855v.063a4.926 4.926 0 003.95 4.827 4.917 4.917 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 20.289a13.941 13.941 0 007.548 2.209"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    className="text-cyan-200 hover:text-cyan-100"
                    href="https://www.linkedin.com/company/infinity-edu-sa/"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <BsLinkedin size={24} />
                  </a>
                </li>
                <li>
                  <a
                    className="text-cyan-200 hover:text-cyan-100"
                    href="https://www.instagram.com/infinity_edu_sa"
                  >
                    <span className="sr-only">Instagram</span>
                    <AiFillInstagram size={24} />
                  </a>
                </li>
                <li>
                  <a
                    className="text-cyan-200 hover:text-cyan-100"
                    href="https://www.youtube.com/@infinity_edu_sa"
                  >
                    <span className="sr-only">Youtube</span>
                    <BsYoutube size={24} />
                  </a>
                </li>
                <li>
                  <a
                    className="text-cyan-200 hover:text-cyan-100"
                    href="https://t.me/infinity_edu_sa"
                  >
                    <span className="sr-only">Telegram</span>
                    <BsTelegram size={24} />
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact form */}
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">
                {t('contactUsDescription')}
              </h3>
              <form
                onSubmit={form.onSubmit(handleSubmit)}
                className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <TextInput
                    required
                    label={t('firstName')}
                    {...form.getInputProps('firstName')}
                  />
                </div>
                <div>
                  <TextInput
                    required
                    label={t('lastName')}
                    {...form.getInputProps('lastName')}
                  />
                </div>
                <div>
                  <TextInput
                    required
                    label={t('email')}
                    {...form.getInputProps('email')}
                  />
                </div>
                <div>
                  <TextInput
                    required
                    label={t('phoneNumber')}
                    {...form.getInputProps('phoneNumber')}
                  />
                </div>
                <div className="">
                  <TextInput
                    required
                    label={t('subject')}
                    {...form.getInputProps('subject')}
                  />
                </div>
                <div className="">
                  <Select
                    data={[
                      { value: 'complaint', label: t('complaint') },
                      { value: 'suggestion', label: t('suggestion') },
                      {
                        value: 'technicalProblem',
                        label: t('technicalProblem'),
                      },
                      {
                        value: 'communicateWithTheSalesTeam',
                        label: t('communicateWithTheSalesTeam'),
                      },
                      {
                        value: 'inquireAboutTheAttendanceCertificate',
                        label: t('inquireAboutTheAttendanceCertificate'),
                      },
                      {
                        value: 'inquireAboutAttendanceHours',
                        label: t('inquireAboutAttendanceHours'),
                      },
                      {
                        value: 'communicateWithTheScientificCommittee',
                        label: t('communicateWithTheScientificCommittee'),
                      },
                      {
                        value: 'communicateWithManagement',
                        label: t('communicateWithManagement'),
                      },
                      { value: 'other', label: t('other') },
                    ]}
                    required
                    label={t('reason')}
                    {...form.getInputProps('reason')}
                  />
                </div>
                <div className="sm:col-span-2">
                  <Textarea
                    label={t('message')}
                    {...form.getInputProps('message')}
                    required
                  />
                </div>
                <div className="flex items-end">
                  {/* <TextInput
                    required
                    label={t('attachment')}
                    {...form.getInputProps('attachment')}
                    className="flex-auto"
                  /> */}

                  <label htmlFor="attachmentUrlFile" className="px-4">
                    {uploadImageLoading ? (
                      <Loader />
                    ) : (
                      <>
                        <SmallButton>{t('upload')}</SmallButton>

                        {form.values.attachment.split('/').reverse()[0]}
                      </>
                    )}
                  </label>
                  {form.values.attachment && (
                    <ActionIcon
                      onClick={() => form.setFieldValue('attachment', '')}
                    >
                      <XIcon
                        className="h-6 w-6 text-red-300"
                        aria-hidden="true"
                      />
                    </ActionIcon>
                  )}
                  <input
                    className="hidden"
                    type="file"
                    name="attachmentUrlFile"
                    id="attachmentUrlFile"
                    placeholder="Upload File"
                    onChange={e => {
                      if (e.target.files) {
                        const image = e.target.files[0]
                        if (image) {
                          setUploadImageLoading(true)
                          handleImage(image).then((link: string) => {
                            form.setFieldValue('attachment', link)
                            setUploadImageLoading(false)
                          })
                        }
                      }
                    }}
                  />
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <Button type="submit" disabled={loading}>
                    {loading ? <Loader /> : t('send')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ContactUsForm
