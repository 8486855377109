type ResultT = {
  path: string
  originalname: string
}

export const handleFile = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  const NEXT_PUBLIC_API =
    process.env.NEXT_PUBLIC_API || 'https://graphql.infinity.edu.sa'
  const res = await fetch(`${NEXT_PUBLIC_API}/uploads`, {
    method: 'POST',
    body: formData,
  })

  const result = await res.json()
  return result as ResultT
}

export const handleImage = async (img: File) => {
  const result = await handleFile(img)
  return result.path
}
export const handleCV = async (cv: File) => {
  const result = await handleFile(cv)
  return result
}
export default handleImage
