import dayjs from 'dayjs'
import Particles from 'react-particles'
import type { Container, Engine } from 'tsparticles-engine'
import { loadSlim } from 'tsparticles-slim'
import { useCallback } from 'react'

const Balloons = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    console.log(engine)
    await loadSlim(engine)
  }, [])
  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await console.log(container)
    },
    [],
  )
  return (
    <>
      {dayjs().isBefore(dayjs('2023-09-24 12:00'), 'm')  && (
          <Particles
            id="tsparticles"
            loaded={particlesLoaded}
            init={particlesInit}
            options={{
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: 'remove',
                  },
                  onHover: {
                    enable: true,
                    mode: 'remove',
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 2,
                    size: 15,
                    duration: 1,
                    opacity: 1,
                    speed: 10,
                  },
                  remove: {
                    particles_nb: 1,
                    distance: 1,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                number: {
                  density: {
                    enable: false,
                    area: 800,
                  },
                  value: 15,
                },
                size: {
                  value: 50,
                },
                opacity: {
                  value: 0.8,
                },
                collisions: {
                  enable: false,
                },
                move: {
                  enable: true,
                  speed: 5,
                  direction: 'top',
                  random: true,
                  out_mode: 'out',
                },
                shape: {
                  type: 'images',
                  image: [
                    {
                      src: '/app-images/balloonG.png',
                      height: 40,
                      width: 20,
                    },
                    {
                      src: '/app-images/balloonW.png',
                      height: 40,
                      width: 20,
                    },
                  ],
                },
              },
            }}
          />
        )}
    </>
  )
}
export default Balloons
