/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react'
import { ContactUsDocument } from '@/types/graphql/types'
import { getErrorMessage } from '@/util/getErrorMessage'
import { useMutation } from '@apollo/client'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import useTranslation from 'next-translate/useTranslation'

const useContact = () => {
  const { t } = useTranslation('client')
  const [uploadImageLoading, setUploadImageLoading] = useState(false)
  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      subject: '',
      message: '',
      reason: '',
      attachment: '',
    },
  })
  // contact mutation
  const [contact, { loading }] = useMutation(ContactUsDocument, {
    onCompleted() {
      showNotification({
        title: t('sent'),
        message: t('messageReceived'),
      })
      form.reset()
    },
    onError(error) {
      showNotification({
        title: t('error'),
        message: getErrorMessage(error),
        color: 'red',
        autoClose: false,
      })
    },
  })
  // submit mutation data
  const handleSubmit = (values: typeof form.values) => {
    contact({
      variables: {
        data: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          message: values.message,
          subject: values.subject,
          phoneNumber: values.phoneNumber,
          reason: values.reason,
          attachment: values.attachment,
        },
      },
    })
  }
  return {
    handleSubmit,
    form,
    loading,
    uploadImageLoading,
    setUploadImageLoading,
  }
}
export default useContact
